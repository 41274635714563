<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="flex flex-wrap items-center">
                <div class="flex-grow">
                    <vs-button
                        @click="bookAMeeting"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-plus">
                        Đặt lịch họp
                    </vs-button>
                </div>
                <!-- ITEMS PER PAGE -->
                <Pagination
                    :itemList="meetingScheduleList"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="meetingScheduleList"
                         :animateRows="true"
                         :pagination="true"
                         :localeText=" {noRowsToShow: 'Không có dữ liệu'}"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../ui-elements/Pagination.vue";
import MeetingScheduleCellRendererActions from "./cell-renderer/MeetingScheduleCellRendererActions.vue"

export default {
    components: {
        AgGridVue,
        // Cell Renderer
        Pagination,
        MeetingScheduleCellRendererActions,
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'Tên cuộc họp',
                    field: 'reservationName',
                    width: 150
                },
                {
                    headerName: 'Mục đích',
                    field: 'purpose',
                    width: 150,
                },
                {
                    headerName: 'Người tham gia',
                    field: 'participants',
                    width: 250,
                },
                {
                    headerName: 'Số người',
                    field: 'capacity',
                    width: 70
                },
                {
                    headerName: 'Thời gian',
                    field: 'datetime',
                    width: 90
                },
                {
                    headerName: 'Ngày họp',
                    field: 'dateBegin',
                    width: 100
                },
                {
                    headerName: 'Hành động',
                    field: 'actions',
                    width: 80,
                    cellRendererFramework: 'MeetingScheduleCellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                MeetingScheduleCellRendererActions,
            },
            meetingScheduleList: []
        }
    },
    created() {
        this.initScreen();
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.deleteMeetingScheduleStatus'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('DELETE_MEETING_SCHEDULE_STATUS', false);
            }
        },
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        bookAMeeting() {
            this.$router.push('/user/book-a-meeting').catch(() => {
            })
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            this.$crm.get('/reservation/all').then((response) => {
                this.meetingScheduleList = response.data.map(item => {
                    //Get Hours
                    let a = (new Date(Date.parse(item.dateBegin)));
                    let b = a.getMinutes();
                    let d = b < 10 ? '0' + b : b;
                    let c = a.getHours().toString() + ':' + d.toString()
                    //Get Minutes
                    let n = (new Date(Date.parse(item.dateEnd)));
                    let m = n.getMinutes();
                    let l = m < 10 ? '0' + m : m;
                    let k = n.getHours().toString() + ':' + l.toString()
                    return {
                        reservationName: item.reservationName,
                        purpose: item.purpose,
                        capacity: item.capacity,
                        datetime: c + '   -   ' + k,
                        dateBegin: item.dateBegin.substring(0, 10),
                        participants: item.participants.map(i => {
                            return i.fullName
                        }),
                        id: item.id
                    };
                });
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>

<style scoped>

</style>
